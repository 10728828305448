import React from 'react';

const TabAsk = ({className}) => (
  <svg className={className} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.5002 21C16.2992 21 21 16.2992 21 10.5001C21 4.70084 16.2992 0 10.5002 0C4.70141 0 0 4.70084 0 10.5001C0 16.2992 4.70141 21 10.5002 21Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M11.4527 5.44464C11.4527 4.88622 10.9999 4.43359 10.4415 4.43359C9.88304 4.43359 9.43051 4.88622 9.43051 5.44464V9.4892H5.38619C4.8271 9.4892 4.375 9.94107 4.375 10.5003C4.375 11.0594 4.8271 11.5113 5.38619 11.5113H9.43051V15.5559C9.43051 16.1143 9.88304 16.5669 10.4415 16.5669C10.9999 16.5669 11.4527 16.1143 11.4527 15.5559V11.5113H15.497C16.0559 11.5113 16.508 11.0594 16.508 10.5003C16.508 9.94107 16.0559 9.4892 15.497 9.4892H11.4527V5.44464Z" fill="white"/>
  </svg>

);

export default TabAsk;
