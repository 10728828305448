import React from 'react';

const CheckIcon = ({className}) => (
  <svg className={className} viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.0078 1.1338L4.63002 5.51203L3.35426 4.28344L3.3307 4.25962C2.71654 3.64564 1.72384 3.64564 1.11029 4.25962C0.496128 4.87381 0.496128 5.86612 1.11029 6.48031L1.13386 6.5037L3.49595 8.86625C4.09694 9.49341 5.07863 9.50362 5.6931 8.88943L5.71636 8.86625L11.2282 3.35407L11.2398 3.34259C11.8607 2.72202 11.8552 1.72417 11.2282 1.1102L11.2166 1.09871C10.5957 0.49133 9.60881 0.50685 9.0078 1.1338Z" fill="white"/>
  </svg>

);

export default CheckIcon;
