import React from 'react';

const BellIcon = ({className}) => (
  <svg className={className} viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12.7836 10.3032L13.957 11.4764C14.1532 11.6727 14.2636 11.9387 14.2636 12.2163V13.3524H0.660156V12.2163C0.660156 11.9387 0.77052 11.6727 0.966723 11.4764L2.14011 10.3032C2.53252 9.91073 2.75299 9.37851 2.75299 8.82354V6.02818C2.75299 3.7838 4.32542 1.91086 6.42744 1.43894C6.42617 1.42794 6.42468 1.41702 6.42319 1.4061C6.41931 1.37768 6.41544 1.34928 6.41544 1.31975C6.41544 0.742939 6.88499 0.273438 7.46185 0.273438C8.03872 0.273438 8.50827 0.742939 8.50827 1.31975C8.50827 1.34949 8.50435 1.37817 8.50042 1.40683C8.49887 1.41819 8.49732 1.42955 8.49601 1.44096C10.6315 1.93309 12.1707 3.92943 12.1707 6.18937V8.82354C12.1707 9.37851 12.3912 9.91073 12.7836 10.3032ZM7.46197 17.0145C6.30621 17.0145 5.36914 16.0775 5.36914 14.9218V14.3987H9.5548V14.9218C9.5548 16.0775 8.61774 17.0145 7.46197 17.0145Z" fill="white"/>
  </svg>

);

export default BellIcon;
