import React from 'react';

const MenIcon = ({className}) => (
  <svg className={className} viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.45035 0C4.69441 0 2.45056 2.28351 2.45056 5.09021C2.45056 7.895 4.69253 10.1798 7.45035 10.1798C10.2069 10.1798 12.4508 7.895 12.4508 5.09021C12.4508 2.28351 10.2069 0 7.45035 0ZM10.7711 10.7906C9.70258 11.6488 7.50031 11.5701 7.50031 11.5701C7.50031 11.5701 5.61624 11.6469 4.54521 10.7906C2.51744 12.1021 0.193597 13.7951 0 16.4632C0.710063 16.5611 1.97344 16.6667 3.77576 16.6667H11.2217C13.0253 16.6667 14.2893 16.5611 15 16.4632C14.8077 13.7932 12.802 12.1027 10.7711 10.7906Z" fill="#ADB8FA"/>
  </svg>

);

export default MenIcon;
