import React from 'react';

const ShareIcon = ({className}) => (
  <svg className={className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.3865 9.9624C11.4535 9.9624 10.6183 10.388 10.0641 11.0551L6.45916 9.00838C6.57127 8.69291 6.63288 8.35371 6.63288 8.00021C6.63288 7.64678 6.57127 7.30758 6.45916 6.99211L10.0638 4.94486C10.618 5.61221 11.4533 6.03796 12.3864 6.03796C14.0506 6.03796 15.4046 4.68347 15.4046 3.01852C15.4047 1.35406 14.0507 0 12.3865 0C10.722 0 9.3679 1.35406 9.3679 3.01845C9.3679 3.37195 9.42945 3.71122 9.54162 4.02676L5.93674 6.07408C5.38262 5.40708 4.54747 4.98162 3.61472 4.98162C1.95004 4.98162 0.595703 6.33568 0.595703 8.00014C0.595703 9.66453 1.95004 11.0186 3.61472 11.0186C4.54747 11.0186 5.38255 10.5931 5.93667 9.9262L9.54162 11.973C9.42945 12.2886 9.36783 12.6279 9.36783 12.9815C9.36783 14.6459 10.722 15.9999 12.3864 15.9999C14.0507 15.9999 15.4047 14.6458 15.4047 12.9815C15.4047 11.3168 14.0507 9.9624 12.3865 9.9624Z" fill="white"/>
  </svg>

);

export default ShareIcon;
