import React from 'react';

const StarsLine = ({className}) => (
  <svg className={className} viewBox="0 0 64 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.86671 0.904715C4.71728 0.914353 4.5866 1.00931 4.53121 1.14834L3.73479 2.97002L1.71869 3.20395C1.56469 3.22137 1.43571 3.32852 1.39073 3.47663L1.39054 3.47726C1.34571 3.62531 1.39293 3.78576 1.51105 3.88599L3.05961 5.19964L2.71159 7.15719C2.68063 7.30412 2.73677 7.45565 2.85604 7.54649C2.97528 7.63734 3.13626 7.65161 3.26948 7.58282L5.08667 6.54375L6.96768 7.59362C7.10207 7.66271 7.26452 7.64751 7.38388 7.55446C7.44833 7.50425 7.494 7.436 7.51679 7.36073C7.53613 7.29686 7.53917 7.22805 7.52342 7.16052L6.96462 5.16514L8.53653 3.81498C8.5871 3.76753 8.62275 3.7086 8.64209 3.64473C8.66483 3.56962 8.6648 3.48802 8.63913 3.41052C8.59187 3.2671 8.46562 3.16432 8.31571 3.14654L6.16695 2.97637L5.23094 1.10278C5.15812 0.972117 5.01632 0.894947 4.86671 0.904715Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M18.5918 0.904715C18.4424 0.914353 18.3117 1.00931 18.2563 1.14834L17.4599 2.97002L15.4438 3.20395C15.2898 3.22137 15.1608 3.32852 15.1158 3.47663L15.1156 3.47726C15.0708 3.62531 15.118 3.78576 15.2361 3.88599L16.7847 5.19964L16.4367 7.15719C16.4057 7.30412 16.4619 7.45565 16.5811 7.54649C16.7004 7.63734 16.8614 7.65161 16.9946 7.58282L18.8118 6.54375L20.6928 7.59362C20.8272 7.66271 20.9896 7.64751 21.109 7.55446C21.1734 7.50425 21.2191 7.436 21.2419 7.36073C21.2612 7.29686 21.2643 7.22805 21.2485 7.16052L20.6897 5.16514L22.2616 3.81498C22.3122 3.76753 22.3478 3.7086 22.3672 3.64473C22.3899 3.56962 22.3899 3.48802 22.3642 3.41052C22.317 3.2671 22.1907 3.16432 22.0408 3.14654L19.8921 2.97637L18.956 1.10278C18.8832 0.972117 18.7414 0.894947 18.5918 0.904715Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M32.3169 0.904715C32.1675 0.914353 32.0368 1.00931 31.9814 1.14834L31.185 2.97002L29.1689 3.20395C29.0149 3.22137 28.8859 3.32852 28.8409 3.47663L28.8407 3.47726C28.7959 3.62531 28.8431 3.78576 28.9612 3.88599L30.5098 5.19964L30.1618 7.15719C30.1308 7.30412 30.187 7.45565 30.3062 7.54649C30.4255 7.63734 30.5865 7.65161 30.7197 7.58282L32.5369 6.54375L34.4179 7.59362C34.5523 7.66271 34.7147 7.64751 34.8341 7.55446C34.8985 7.50425 34.9442 7.436 34.967 7.36073C34.9863 7.29686 34.9894 7.22805 34.9736 7.16052L34.4148 5.16514L35.9867 3.81498C36.0373 3.76753 36.0729 3.7086 36.0923 3.64473C36.115 3.56962 36.115 3.48802 36.0893 3.41052C36.0421 3.2671 35.9158 3.16432 35.7659 3.14654L33.6172 2.97637L32.6811 1.10278C32.6083 0.972117 32.4665 0.894947 32.3169 0.904715Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M45.6607 0.904715C45.5112 0.914353 45.3805 1.00931 45.3252 1.14834L44.5287 2.97002L42.5126 3.20395C42.3586 3.22137 42.2297 3.32852 42.1847 3.47663L42.1845 3.47726C42.1397 3.62531 42.1869 3.78576 42.305 3.88599L43.8536 5.19964L43.5055 7.15719C43.4746 7.30412 43.5307 7.45565 43.65 7.54649C43.7692 7.63734 43.9302 7.65161 44.0634 7.58282L45.8806 6.54375L47.7616 7.59362C47.896 7.66271 48.0585 7.64751 48.1778 7.55446C48.2423 7.50425 48.2879 7.436 48.3107 7.36073C48.3301 7.29686 48.3331 7.22805 48.3174 7.16052L47.7586 5.16514L49.3305 3.81498C49.381 3.76753 49.4167 3.7086 49.436 3.64473C49.4588 3.56962 49.4587 3.48802 49.4331 3.41052C49.3858 3.2671 49.2596 3.16432 49.1097 3.14654L46.9609 2.97637L46.0249 1.10278C45.9521 0.972117 45.8103 0.894947 45.6607 0.904715Z" fill="#ADB8FA" fillOpacity="0.5"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M59.0049 0.904715C58.8555 0.914353 58.7248 1.00931 58.6694 1.14834L57.873 2.97002L55.8569 3.20395C55.7029 3.22137 55.5739 3.32852 55.5289 3.47663L55.5287 3.47726C55.4839 3.62531 55.5311 3.78576 55.6492 3.88599L57.1978 5.19964L56.8498 7.15719C56.8188 7.30412 56.875 7.45565 56.9942 7.54649C57.1135 7.63734 57.2744 7.65161 57.4077 7.58282L59.2248 6.54375L61.1059 7.59362C61.2403 7.66271 61.4027 7.64751 61.5221 7.55446C61.5865 7.50425 61.6322 7.436 61.655 7.36073C61.6743 7.29686 61.6773 7.22805 61.6616 7.16052L61.1028 5.16514L62.6747 3.81498C62.7253 3.76753 62.7609 3.7086 62.7803 3.64473C62.803 3.56962 62.803 3.48802 62.7773 3.41052C62.7301 3.2671 62.6038 3.16432 62.4539 3.14654L60.3051 2.97637L59.3691 1.10278C59.2963 0.972117 59.1545 0.894947 59.0049 0.904715Z" fill="#ADB8FA" fillOpacity="0.5"/>
  </svg>

);

export default StarsLine;
