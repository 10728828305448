import React from 'react';

const TabSettings = ({className}) => (
  <svg className={className} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.94042 0C5.63329 0 2.94067 2.87722 2.94067 6.41366C2.94067 9.94771 5.63104 12.8265 8.94042 12.8265C12.2483 12.8265 14.9409 9.94771 14.9409 6.41366C14.9409 2.87722 12.2483 0 8.94042 0ZM12.9253 13.5962C11.6431 14.6775 9.00037 14.5783 9.00037 14.5783C9.00037 14.5783 6.73949 14.6751 5.45426 13.5962C3.02093 15.2486 0.232316 17.3819 0 20.7437C0.852075 20.867 2.36813 21 4.53091 21H13.4661C15.6304 21 17.1472 20.867 18 20.7437C17.7692 17.3794 15.3624 15.2494 12.9253 13.5962Z" />
  </svg>

);

export default TabSettings;
