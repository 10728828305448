import React from 'react';

const LogoWhite = ({className}) => (
  <svg className={className} viewBox="0 0 107 39" style={{fill: "none"}} xmlns="http://www.w3.org/2000/svg">
    <path d="M23.6299 28.4249H12.6908L10.4021 35H5.30103L15.9686 6.75998H20.3714L31.0584 35H25.938L23.6299 28.4249ZM14.0678 24.4682H22.2528L18.1603 12.7532L14.0678 24.4682ZM44.3941 29.2977C44.3941 28.4572 44.0449 27.8172 43.3467 27.3775C42.6614 26.9379 41.517 26.55 39.9137 26.2138C38.3103 25.8776 36.972 25.4509 35.8988 24.9337C33.5454 23.7958 32.3688 22.1472 32.3688 19.9878C32.3688 18.1775 33.1317 16.6647 34.6575 15.4492C36.1832 14.2338 38.1228 13.626 40.4761 13.626C42.9846 13.626 45.0082 14.2467 46.547 15.488C48.0986 16.7293 48.8744 18.3392 48.8744 20.3175H44.1613C44.1613 19.4124 43.8251 18.6624 43.1527 18.0676C42.4804 17.4599 41.5882 17.156 40.4761 17.156C39.4417 17.156 38.5948 17.3952 37.9353 17.8737C37.2888 18.3521 36.9655 18.9922 36.9655 19.7938C36.9655 20.5179 37.2694 21.0804 37.8771 21.4813C38.4849 21.8821 39.7132 22.2894 41.5623 22.7032C43.4113 23.104 44.8595 23.5889 45.9069 24.1579C46.9672 24.7139 47.7495 25.3862 48.2538 26.175C48.771 26.9637 49.0296 27.9206 49.0296 29.0455C49.0296 30.9334 48.2473 32.4656 46.6827 33.6423C45.1182 34.806 43.0687 35.3879 40.5343 35.3879C38.8146 35.3879 37.2823 35.0776 35.9376 34.4569C34.5928 33.8363 33.5454 32.9829 32.7955 31.8967C32.0455 30.8105 31.6705 29.6403 31.6705 28.3861H36.2479C36.3125 29.4981 36.7328 30.358 37.5086 30.9657C38.2844 31.5605 39.3124 31.8579 40.5925 31.8579C41.8338 31.8579 42.7778 31.6252 43.4243 31.1597C44.0708 30.6812 44.3941 30.0606 44.3941 29.2977ZM58.7771 26.0004L56.6823 28.1533V35H51.9692V5.20833H56.6823V22.3928L58.1564 20.5503L63.9557 14.0139H69.6192L61.8222 22.7614L70.4532 35H65.0031L58.7771 26.0004Z" fill="white"/>
    <path d="M80.7632 35.3879C77.7762 35.3879 75.3518 34.4505 73.4898 32.5755C71.6408 30.6877 70.7162 28.1792 70.7162 25.05V24.4682C70.7162 22.3735 71.1171 20.505 71.9188 18.8628C72.7334 17.2078 73.8712 15.9212 75.3324 15.0031C76.7935 14.0851 78.4228 13.626 80.2201 13.626C83.0777 13.626 85.2823 14.5376 86.834 16.3608C88.3986 18.184 89.1809 20.7636 89.1809 24.0997V26.0004H75.4682C75.6104 27.7331 76.1858 29.1037 77.1944 30.1123C78.2159 31.1209 79.496 31.6252 81.0347 31.6252C83.1941 31.6252 84.9526 30.7524 86.3103 29.0068L88.8511 31.4312C88.0107 32.6855 86.8857 33.6617 85.4763 34.3599C84.0798 35.0453 82.5088 35.3879 80.7632 35.3879ZM80.2007 17.4082C78.9076 17.4082 77.8603 17.8607 77.0586 18.7659C76.2698 19.671 75.7656 20.9317 75.5457 22.548H84.5259V22.1989C84.4225 20.6214 84.0022 19.4318 83.2652 18.6301C82.5282 17.8155 81.5067 17.4082 80.2007 17.4082ZM102.652 18.3198C102.032 18.2163 101.392 18.1646 100.732 18.1646C98.5727 18.1646 97.1181 18.9922 96.3681 20.6472V35H91.655V14.0139H96.1547L96.2711 16.3608C97.409 14.5376 98.9865 13.626 101.004 13.626C101.676 13.626 102.232 13.7165 102.672 13.8976L102.652 18.3198ZM104.001 30.0347C104.881 30.0347 105.56 30.2869 106.038 30.7912C106.516 31.2825 106.756 31.9032 106.756 32.6531C106.756 33.3902 106.516 34.0044 106.038 34.4957C105.56 34.9741 104.881 35.2134 104.001 35.2134C103.161 35.2134 102.495 34.9741 102.004 34.4957C101.512 34.0173 101.267 33.4031 101.267 32.6531C101.267 31.9032 101.506 31.2825 101.984 30.7912C102.463 30.2869 103.135 30.0347 104.001 30.0347Z" fill="#00D9CD"/>
  </svg>

);

export default LogoWhite;
