import React from 'react';

const ContactName = ({className}) => (
  <svg className={className} viewBox="0 0 40 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M17.2912 32.8594C25.5755 32.8594 32.2911 26.1437 32.2911 17.8595C32.2911 9.57494 25.5755 2.85922 17.2912 2.85922C9.00694 2.85922 2.29108 9.57494 2.29108 17.8595C2.29108 26.1437 9.00694 32.8594 17.2912 32.8594Z" fill="#ADB8FA"/>
    <path d="M18.0584 9.33203C15.5334 9.33203 13.4776 11.4435 13.4776 14.0386C13.4776 16.6321 15.5317 18.7447 18.0584 18.7447C20.5839 18.7447 22.6397 16.6321 22.6397 14.0386C22.6397 11.4435 20.5839 9.33203 18.0584 9.33203ZM21.6459 18.7447C20.6669 19.5382 19.4476 20.0302 18.1042 20.0302C16.7607 20.0302 15.5426 19.5365 14.5613 18.7447C12.7035 19.9573 11.4098 22.0876 11.2324 24.5546C11.883 24.6451 13.0405 24.7427 14.6917 24.7427H21.5137C23.1661 24.7427 24.3242 24.6451 24.9753 24.5546C24.7991 22.0858 23.5066 19.9579 21.6459 18.7447Z" fill="white"/>
  </svg>

);

export default ContactName;
