import React from "react";

const Search = ({ className }) => (
  <svg
    className={className}
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.881 11.3498L15.7607 15.4105C16.0889 15.754 16.0781 16.3017 15.737 16.632C15.5764 16.7875 15.365 16.873 15.142 16.873C14.9065 16.873 14.6868 16.7789 14.5234 16.6081L10.6141 12.5167C9.50411 13.3017 8.2058 13.7152 6.8434 13.7152C3.21432 13.7152 0.261719 10.744 0.261719 7.09198C0.261719 3.43999 3.21432 0.46875 6.8434 0.46875C10.4725 0.46875 13.4251 3.43999 13.4251 7.09198C13.4251 8.65506 12.8785 10.1565 11.881 11.3498ZM11.7079 7.09483C11.7079 4.39544 9.52561 2.1994 6.84315 2.1994C4.16068 2.1994 1.97843 4.39544 1.97843 7.09483C1.97843 9.79423 4.16068 11.9903 6.84315 11.9903C9.52561 11.9903 11.7079 9.79423 11.7079 7.09483Z"
      fill="white"
    />
  </svg>
);

export default Search;
