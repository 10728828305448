import React from 'react';

const QuestionOption = ({className}) => (
  <svg className={className} viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M19.2414 16.6915C18.3979 17.6461 18.1303 18.9363 18.5743 20.1034L18.8896 20.9315C18.4917 21.3643 18.0707 21.778 17.6287 22.1714L15.6702 20.7995C14.8437 20.2216 13.7988 19.9924 12.7852 20.1691C12.1316 20.2837 11.3999 20.385 10.8685 20.385C4.86556 20.385 1.1891e-07 15.822 1.1891e-07 10.1925C-0.000878811 4.56864 4.87085 0 10.8685 0C16.8708 0 21.7371 4.56298 21.7371 10.1925C21.7372 12.6624 20.7998 14.9275 19.2414 16.6915Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.901 10.1586C11.8671 10.9166 11.8481 11.4455 11.8481 12.3623C11.8481 12.6973 11.6602 13.0849 10.7389 13.0849C9.96829 13.0849 9.70519 12.8206 9.70519 11.9038C9.70519 10.3877 10.4196 9.66483 10.9646 9.22412C11.585 8.7305 12.6377 8.18398 12.6377 7.23207C12.6377 6.42114 11.8859 6.03321 10.9458 6.03321C9.02838 6.03321 9.44192 7.39066 8.42679 7.39066C7.91923 7.39066 7.29883 7.07336 7.29883 6.38571C7.29883 5.43392 8.4644 4.02344 11.0024 4.02344C13.4086 4.02344 15.0065 5.2752 15.0065 6.93223C15.0065 8.58938 13.4086 9.78836 12.901 10.1586ZM12.2054 15.6592C12.2054 16.3819 11.5662 17.0167 10.7578 17.0167C9.94967 17.0167 9.32927 16.3819 9.32927 15.6592C9.32927 14.9187 9.96847 14.3016 10.7578 14.3016C11.5474 14.3016 12.2054 14.9187 12.2054 15.6592Z" fill="#6375FC"/>
  </svg>

);

export default QuestionOption;
