import React from 'react';

const TwoMenIcon = ({className}) => (
  <svg className={className} viewBox="0 5 33 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M15.0002 34.4414C23.2844 34.4414 30.0001 27.7257 30.0001 19.4415C30.0001 11.157 23.2844 4.44126 15.0002 4.44126C6.71592 4.44126 6e-05 11.157 6e-05 19.4415C6e-05 27.7257 6.71592 34.4414 15.0002 34.4414Z" fill="#ADB8FA"/>
    <path d="M12.494 17.1145C14.0335 17.1145 15.2687 15.8728 15.2687 14.3333C15.2687 12.7938 14.0335 11.5586 12.494 11.5586C10.9545 11.5586 9.70632 12.8003 9.70632 14.3398C9.70632 15.8793 10.948 17.1145 12.494 17.1145ZM12.5764 18.9706C10.3843 18.9706 6 20.054 6 22.2143V24.533H12.5764V22.4485C12.5764 21.6629 12.8872 20.2817 14.8068 19.2301C13.9887 19.0653 13.1973 18.9706 12.5764 18.9706Z" fill="white"/>
    <path d="M19.672 19.8977C21.4456 19.8977 25.0002 20.7527 25.0002 22.4447V24.5291H14.3438V22.4447C14.3438 20.7527 17.8983 19.8977 19.672 19.8977ZM19.9068 13.4102C21.1868 13.4102 22.219 14.4495 22.219 15.7288C22.219 17.0081 21.1861 18.0474 19.9068 18.0474C18.6268 18.0474 17.5882 17.0081 17.5882 15.7288C17.5882 14.4495 18.6268 13.4102 19.9068 13.4102Z" fill="white"/>
  </svg>

);

export default TwoMenIcon;
