import React from 'react';

const ArrowBack = ({className}) => (
  <svg className={className} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path style={{fillRule: "evenodd", clipRule: "evenodd", opacity: "0.6", fill: "white"}} d="M18.7502 8.74919H4.81267L10.9219 2.63963C11.4149 2.14709 11.4078 1.35765 10.9066 0.87383C10.4053 0.39023 9.60203 0.39703 9.10935 0.88979L0.0991905 9.90015C0.0444105 9.95481 0.0444105 10.0431 0.0991905 10.0979L9.12527 19.124C9.60907 19.6079 10.3912 19.6079 10.875 19.1238C11.3589 18.6402 11.3607 17.8561 10.879 17.3699L4.81267 11.2489H18.7502C19.4412 11.2489 20.0003 10.6902 20.0003 9.99905C20.0003 9.30767 19.4412 8.74919 18.7502 8.74919Z" />
  </svg>

);

export default ArrowBack;
