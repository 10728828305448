import React from 'react';

const Logo = ({className}) => (
  <svg className={className} viewBox="0 0 67 19" style={{fill: "none"}}  xmlns="http://www.w3.org/2000/svg">
    <path d="M11.86 14.4844H5.69593L4.52406 18H0.785778L7.13734 0.9375H10.3952L16.7819 18H13.0436L11.86 14.4844ZM6.64515 11.6367H10.9108L8.76625 5.25L6.64515 11.6367ZM25.0202 14.4961C25.0202 14.082 24.8131 13.7578 24.3991 13.5234C23.9928 13.2812 23.3366 13.0664 22.4303 12.8789C19.4147 12.2461 17.9069 10.9648 17.9069 9.03516C17.9069 7.91016 18.3717 6.97266 19.3014 6.22266C20.2389 5.46484 21.4616 5.08594 22.9694 5.08594C24.5787 5.08594 25.8639 5.46484 26.8248 6.22266C27.7936 6.98047 28.278 7.96484 28.278 9.17578H24.8912C24.8912 8.69141 24.735 8.29297 24.4225 7.98047C24.11 7.66016 23.6217 7.5 22.9577 7.5C22.3873 7.5 21.9459 7.62891 21.6334 7.88672C21.3209 8.14453 21.1647 8.47266 21.1647 8.87109C21.1647 9.24609 21.3405 9.55078 21.692 9.78516C22.0514 10.0117 22.653 10.2109 23.4967 10.3828C24.3405 10.5469 25.0514 10.7344 25.6295 10.9453C27.4186 11.6016 28.3131 12.7383 28.3131 14.3555C28.3131 15.5117 27.817 16.4492 26.8248 17.168C25.8327 17.8789 24.5514 18.2344 22.9811 18.2344C21.9186 18.2344 20.9733 18.0469 20.1452 17.6719C19.3248 17.2891 18.6803 16.7695 18.2116 16.1133C17.7428 15.4492 17.5084 14.7344 17.5084 13.9688H20.7194C20.7506 14.5703 20.9733 15.0312 21.3873 15.3516C21.8014 15.6719 22.3561 15.832 23.0514 15.832C23.6998 15.832 24.1881 15.7109 24.5162 15.4688C24.8522 15.2188 25.0202 14.8945 25.0202 14.4961ZM35.11 12.9141L33.8912 14.1328V18H30.5045V0H33.8912V9.97266L34.5475 9.12891L37.7936 5.32031H41.86L37.278 10.6055L42.2584 18H38.3678L35.11 12.9141Z" style={{fill: "#4B494B"}}/>
    <path d="M49.067 18.2344C47.2623 18.2344 45.7975 17.668 44.6725 16.5352C43.5553 15.3945 42.9967 13.8789 42.9967 11.9883V11.6367C42.9967 10.3711 43.2389 9.24219 43.7233 8.25C44.2155 7.25 44.903 6.47266 45.7858 5.91797C46.6686 5.36328 47.653 5.08594 48.7389 5.08594C50.4655 5.08594 51.7975 5.63672 52.735 6.73828C53.6803 7.83984 54.153 9.39844 54.153 11.4141V12.5625H45.8678C45.9537 13.6094 46.3014 14.4375 46.9108 15.0469C47.528 15.6562 48.3014 15.9609 49.2311 15.9609C50.5358 15.9609 51.5983 15.4336 52.4186 14.3789L53.9537 15.8438C53.4459 16.6016 52.7662 17.1914 51.9147 17.6133C51.0709 18.0273 50.1217 18.2344 49.067 18.2344ZM48.7272 7.37109C47.9459 7.37109 47.3131 7.64453 46.8287 8.19141C46.3522 8.73828 46.0475 9.5 45.9147 10.4766H51.3405V10.2656C51.278 9.3125 51.0241 8.59375 50.5787 8.10938C50.1334 7.61719 49.5162 7.37109 48.7272 7.37109ZM63.0123 7.92188C62.6373 7.85938 62.2506 7.82812 61.8522 7.82812C60.5475 7.82812 59.6686 8.32812 59.2155 9.32812V18H56.3678V5.32031H59.0866L59.1569 6.73828C59.8444 5.63672 60.7975 5.08594 62.0162 5.08594C62.4225 5.08594 62.7584 5.14063 63.0241 5.25L63.0123 7.92188ZM64.5475 15C65.0788 15 65.4889 15.1523 65.778 15.457C66.067 15.7539 66.2116 16.1289 66.2116 16.582C66.2116 17.0273 66.067 17.3984 65.778 17.6953C65.4889 17.9844 65.0788 18.1289 64.5475 18.1289C64.0397 18.1289 63.6373 17.9844 63.3405 17.6953C63.0436 17.4062 62.8952 17.0352 62.8952 16.582C62.8952 16.1289 63.0397 15.7539 63.3288 15.457C63.6178 15.1523 64.0241 15 64.5475 15Z" style={{fill: "#5468FF"}} />
  </svg>

);

export default Logo;
