import React from 'react';

const ArrowDropdown = ({className}) => (
  <svg className={className} viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M6.28553 5.49837C6.28553 5.49773 6.28553 5.4971 6.28553 5.49668C6.28553 5.27443 6.19342 5.0741 6.04531 4.93097L6.04562 4.93076L1.33123 0.216733L1.32939 0.218647C1.18801 0.083171 0.996758 2.2e-05 0.786219 2.2e-05C0.351687 2.2e-05 8.4e-05 0.351989 8.4e-05 0.785829C8.4e-05 1.02402 0.106267 1.23754 0.273959 1.38173L4.39041 5.49816L0.2198 9.66882L0.220101 9.66924C0.08393 9.81025 8.4e-05 10.0023 8.4e-05 10.2143C8.4e-05 10.6482 0.351687 10.9999 0.786219 10.9999C0.997367 10.9999 1.18955 10.9161 1.33123 10.7796V10.78L6.04562 6.06577H6.04531C6.19342 5.92243 6.28553 5.7221 6.28553 5.50008C6.28553 5.49923 6.28553 5.4988 6.28553 5.49837Z" />
  </svg>

);

export default ArrowDropdown;
