import React from 'react';

const PlusIcon = ({className}) => (
  <svg className={className} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.0415 1.63737C10.0415 0.892667 9.43772 0.289062 8.69296 0.289062C7.9482 0.289062 7.3447 0.892667 7.3447 1.63737V7.03107H1.95109C1.20547 7.03107 0.602539 7.63367 0.602539 8.3794C0.602539 9.1251 1.20547 9.72772 1.95109 9.72772H7.3447V15.1214C7.3447 15.8661 7.9482 16.4697 8.69296 16.4697C9.43772 16.4697 10.0415 15.8661 10.0415 15.1214V9.72772H15.4351C16.1805 9.72772 16.7834 9.1251 16.7834 8.3794C16.7834 7.63367 16.1805 7.03107 15.4351 7.03107H10.0415V1.63737Z" />
  </svg>

);

export default PlusIcon;
