import React from 'react';

const TabAnswer = ({className}) => (
  <svg className={className} viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M18.5889 16.9389C17.774 17.9077 17.5156 19.217 17.9445 20.4014L18.2491 21.2418C17.8646 21.681 17.458 22.1008 17.0309 22.5L15.1389 21.1078C14.3404 20.5213 13.3309 20.2887 12.3517 20.4681C11.7202 20.5844 11.0133 20.6872 10.5 20.6872C4.70058 20.6872 1.14878e-07 16.0566 1.14878e-07 10.3436C-0.000849012 4.63636 4.70569 0 10.5 0C16.2987 0 21 4.63062 21 10.3436C21.0002 12.85 20.0945 15.1488 18.5889 16.9389Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.464 10.3081C11.4651 11.0774 11.4468 11.6141 11.4468 12.5445C11.4468 12.8844 11.2653 13.2779 10.3752 13.2779C9.6307 13.2779 9.37653 13.0095 9.37653 12.0792C9.37653 10.5406 10.0667 9.80705 10.5932 9.3598C11.1926 8.85887 12.2096 8.30425 12.2096 7.33822C12.2096 6.51528 11.4833 6.12159 10.5751 6.12159C8.72267 6.12159 9.12218 7.49916 8.14147 7.49916C7.65112 7.49916 7.05176 7.17716 7.05176 6.47932C7.05176 5.51342 8.17781 4.08203 10.6297 4.08203C12.9543 4.08203 14.4981 5.35235 14.4981 7.03394C14.4981 8.71565 12.9543 9.93241 12.464 10.3081ZM11.7921 15.8904C11.7921 16.6238 11.1746 17.2679 10.3935 17.2679C9.61284 17.2679 9.01348 16.6238 9.01348 15.8904C9.01348 15.1389 9.63101 14.5127 10.3935 14.5127C11.1564 14.5127 11.7921 15.1389 11.7921 15.8904Z" fill="white"/>
  </svg>

);

export default TabAnswer;
