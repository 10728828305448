import React from 'react';

const QuestionSmall = ({className}) => (
  <svg className={className} viewBox="0 0 26 28" style={{fill: "none"}} xmlns="http://www.w3.org/2000/svg">
    <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#7685FE"}} d="M23.0528 20.7031C22.0568 21.8872 21.7409 23.4874 22.2651 24.9351L22.6375 25.9622C22.1676 26.499 21.6705 27.0121 21.1486 27.5L18.8361 25.7984C17.8601 25.0816 16.6264 24.7973 15.4296 25.0165C14.6577 25.1587 13.7938 25.2843 13.1663 25.2843C6.07816 25.2843 0.333008 19.6247 0.333008 12.6422C0.33197 5.66667 6.08441 0 13.1663 0C20.2537 0 25.9997 5.65965 25.9997 12.6422C25.9999 15.7056 24.8929 18.5152 23.0528 20.7031Z" />
    <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "white"}}  d="M15.5671 12.5979C14.3463 13.5381 14.3238 14.1942 14.3238 15.3312C14.3238 15.7468 14.102 16.2276 13.0142 16.2276C12.1042 16.2276 11.7935 15.8997 11.7935 14.7626C11.7935 12.8821 12.6371 11.9855 13.2806 11.4389C14.0132 10.8266 15.2562 10.1488 15.2562 8.96807C15.2562 7.96225 14.3685 7.48108 13.2584 7.48108C10.9944 7.48108 11.4827 9.16478 10.284 9.16478C9.6847 9.16478 8.95215 8.77121 8.95215 7.9183C8.95215 6.73775 10.3284 4.98828 13.3252 4.98828C16.1664 4.98828 18.0532 6.5409 18.0532 8.59617C18.0532 10.6516 16.1664 12.1387 15.5671 12.5979ZM14.7457 19.4202C14.7457 20.3166 13.9909 21.1039 13.0363 21.1039C12.0822 21.1039 11.3496 20.3166 11.3496 19.4202C11.3496 18.5016 12.1044 17.7363 13.0363 17.7363C13.9687 17.7363 14.7457 18.5016 14.7457 19.4202Z" />
  </svg>
);

export default QuestionSmall;
