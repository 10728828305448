import React from 'react';

const MessageIcon = ({className}) => (
  <svg className={className} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.67004 0.0703125C4.07658 0.0703125 0.340069 3.80386 0.340069 8.3934C0.340069 9.8453 0.720067 11.2718 1.4404 12.5265L0.0720093 16.6273C0.0393785 16.7258 0.0631654 16.8338 0.133916 16.9094C0.188199 16.9672 0.263219 16.9985 0.340069 16.9985C0.364161 16.9985 0.387948 16.9954 0.41204 16.9892L4.89236 15.8114C6.05614 16.4039 7.35988 16.7165 8.67004 16.7165C13.2635 16.7165 17 12.9829 17 8.3934C17 3.80386 13.2635 0.0703125 8.67004 0.0703125ZM4.8579 9.66297C4.23516 9.66297 3.7286 9.15683 3.7286 8.53442C3.7286 7.91202 4.23516 7.4059 4.8579 7.4059C5.48095 7.4059 5.98751 7.91202 5.98751 8.53442C5.98751 9.15683 5.48095 9.66297 4.8579 9.66297ZM8.52883 9.66297C7.90608 9.66297 7.39922 9.15683 7.39922 8.53442C7.39922 7.91202 7.90608 7.4059 8.52883 7.4059C9.15159 7.4059 9.65845 7.91202 9.65845 8.53442C9.65845 9.15683 9.15159 9.66297 8.52883 9.66297ZM12.2002 9.66283C11.5771 9.66283 11.0705 9.15669 11.0705 8.53429C11.0705 7.91188 11.5771 7.40576 12.2002 7.40576C12.8232 7.40576 13.3295 7.91188 13.3295 8.53429C13.3295 9.15669 12.8232 9.66283 12.2002 9.66283Z" fill="white"/>
  </svg>

);

export default MessageIcon;
