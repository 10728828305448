import React from 'react';

const ContactPhone = ({className}) => (
  <svg className={className} viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1858_1081)">
      <path fillRule="evenodd" clipRule="evenodd" d="M17.0079 35.4297C25.2922 35.4297 32.0078 28.7138 32.0078 20.4296C32.0078 12.1452 25.2922 5.42969 17.0079 5.42969C8.72367 5.42969 2.00781 12.1452 2.00781 20.4296C2.00781 28.7138 8.72367 35.4297 17.0079 35.4297Z" fill="#ADB8FA"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M22.4144 12.2547C22.0523 11.8921 21.6226 11.7109 21.1268 11.7109H13.8006C13.3048 11.7109 12.8754 11.8921 12.5127 12.2547C12.1504 12.6171 11.9688 13.0462 11.9688 13.5425V28.1948C11.9688 28.6909 12.1504 29.12 12.5127 29.4826C12.8754 29.8452 13.3048 30.0263 13.8006 30.0263H21.1268C21.6226 30.0263 22.0523 29.845 22.4144 29.4826C22.7768 29.12 22.9584 28.6909 22.9584 28.1948V13.5425C22.9584 13.0462 22.777 12.6173 22.4144 12.2547ZM21.5852 25.9054C21.5852 26.0294 21.5396 26.1373 21.4489 26.2277C21.358 26.3181 21.2507 26.3631 21.1271 26.3631H13.8006C13.6764 26.3631 13.5692 26.3179 13.4788 26.2277C13.3881 26.1373 13.3428 26.0294 13.3428 25.9054V15.8321C13.3428 15.708 13.3881 15.6006 13.4788 15.5102C13.5694 15.4196 13.6764 15.374 13.8006 15.374H21.1268C21.2507 15.374 21.358 15.4196 21.4489 15.5102C21.5396 15.6006 21.5846 15.708 21.5846 15.8321V25.9054H21.5852Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_1858_1081">
        <rect width="34.9639" height="34.9639" fill="white" transform="translate(0.0078125 0.429688)"/>
      </clipPath>
    </defs>
  </svg>

);

export default ContactPhone;
