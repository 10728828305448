import React from 'react';

const QuestionLogin = ({className}) => (
  <svg className={className} viewBox="0 0 34 34" style={{fill: "none"}} xmlns="http://www.w3.org/2000/svg">
    <path d="M17 0C26.5753 0 34 7.01613 34 16.4885C34 21.4436 31.9685 25.7272 28.662 28.685C28.3858 28.9314 28.2157 29.2799 28.2072 29.6539L28.1137 32.6796C28.084 33.6443 27.0895 34.2732 26.2055 33.8822L22.831 32.3949C22.5463 32.2674 22.2232 32.2461 21.9215 32.3269C20.3702 32.7518 18.7213 32.9813 17 32.9813C7.42475 32.9813 0 25.9652 0 16.4928C0 7.02038 7.42475 0 17 0Z" style={{fill: "url(#paint0_radial_1860_6364)"}}/>
    <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#19B3AA", fillOpacity: "0.5"}} d="M30 27.5C29 28.5 28 28.5 28.05 32.3002L26.9167 33.1502L25.7833 33.7169C24.4812 32.8728 22.7038 32.4569 20.5858 32.4569C19.5268 32.4569 18.1148 32.7842 17.4088 32.7842C15.4106 27.2725 15.4833 27.6247 15.0539 26.1727C17.0493 24.3576 18.6154 26.1727 15.8747 21.4536C21.3561 15.6453 20.9339 15.3972 22.5 8.5L33.5 20C33.5 20 33.559 21.2089 32.5 23.5C32.3487 23.8273 31 26.5 30 27.5Z" />
    <path d="M18.5 25.5L22.5 8.5L18.5 7.5L12 11L18.5 25.5Z" style={{fill: "#19B3AA", fillOpacity: "0.5"}}/>
    <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "white"}} d="M20.722 16.4788C19.1123 17.6499 19.0827 18.4671 19.0827 19.8834C19.0827 20.401 18.7902 21 17.3559 21C16.1561 21 15.7464 20.5915 15.7464 19.1752C15.7464 16.8327 16.8587 15.7159 17.7072 15.035C18.6731 14.2724 20.3121 13.428 20.3121 11.9573C20.3121 10.7044 19.1416 10.1051 17.6779 10.1051C14.6927 10.1051 15.3366 12.2023 13.7561 12.2023C12.9659 12.2023 12 11.7121 12 10.6497C12 9.17918 13.8147 7 17.7661 7C21.5122 7 24 8.93397 24 11.4941C24 14.0544 21.5122 15.9068 20.722 16.4788ZM20.0227 24.4822C20.0227 25.5989 19.0182 26.5797 17.7478 26.5797C16.4779 26.5797 15.5029 25.5989 15.5029 24.4822C15.5029 23.3379 16.5074 22.3845 17.7478 22.3845C18.9886 22.3845 20.0227 23.3379 20.0227 24.4822Z" />
    <defs>
      <radialGradient id="paint0_radial_1860_6364" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27.455 33.8119) rotate(180) scale(37.0464 37.043)">
        <stop style={{stopColor:"#0B9F97"}}/>
        <stop offset="0.6098"  style={{stopColor:"#00D9CD"}}/>
        <stop offset="0.9348" style={{stopColor:"#06FBED"}} />
        <stop offset="1" style={{stopColor:"#92FFF2"}}/>
      </radialGradient>
    </defs>
  </svg>
);

export default QuestionLogin;
